<template>

  <div>
    <CRow>
      <!-- <CCol sm="6" lg="3">
          <CWidgetIcon
            :header="dr"
            text="Direct Referal"
            color="gradient-info"
            :icon-padding="false"
          >
            <CIcon name="cil-people" width="24"/>
          </CWidgetIcon>
      </CCol> -->
      <CCol sm="6" lg="4">
          <CWidgetIcon
            :header="dr"
            text="Direct Quantification"
            color="primary"
            :icon-padding="false"
          >
            <CIcon name="cil-people" width="24"/>
          </CWidgetIcon>
      </CCol>
      <CCol sm="6" lg="4">
          <CWidgetIcon
            :header="tq"
            text="Team Quantification"
            color="primary"
            :icon-padding="false"
          >
            <CIcon name="cil-people" width="24"/>
          </CWidgetIcon>
      </CCol>
      <CCol sm="6" lg="4">
          <CWidgetIcon
            :header="at"
            text="All Team"
            color="primary"
            :icon-padding="false"
          >
            <CIcon name="cil-people" width="24"/>
          </CWidgetIcon>
      </CCol>
      <CCol lg="12">
        <CCard color="primary">
          <CCardHeader>
            <slot name="header">
              <!-- <CIcon name="cil-grid"/> -->
              <h4 class="float-left  text-white">Affiliate Program</h4>
            </slot>
          </CCardHeader>
          <CCardBody class=''>
              <div class="row">
              <div class="col-sm-12 col-lg-6">
              <h5 class="p-2 text-white">
                Refer BlockScan.info and earn 10% per staking activities!
              </h5>
              <div class="affiliate_numberedlist  text-white">
                <div>
                  <div>1</div>
                  <div>10% earned for every new unique staking user you refer.</div>
                </div>
                <div>
                  <div>2</div>
                  <div>Affiliate program 1% for 10th level deepth.</div>
                </div>
                <div>
                  <div>3</div>
                  <div>Payouts can be withdraw minimum 5$.</div>
                </div>
                <!-- <div>
                  <div>4</div>
                  <div>Your referral link below uniquely identifies your account. Use this code when linking to Vultr.com and start earning today!</div>
                </div> -->
              </div>
              </div>
              <div class="col-sm-12 col-lg-6">
              <hr class="hr10">
                  <div class="affiliate_link innerblock">
                    <CCard color="secondary" class="text-center" body-wrapper>
                         <h3 class="panel-title">Share Your Link</h3>
                        <div>
                          <input type="text" name="campaign_url" v-model="refer" class="flexwidth100 form-control" readonly="readonly">
                          <span class="inputformiconoverlay">
                            <span class="fonticoncopy" data-popup2="Q29weSBMaW5rIHRvIENsaXBib2FyZA==" onclick="document.querySelector('input[name=campaign_url]').select(); document.execCommand('copy');" data-original-title="Copy Link to Clipboard"></span>
                          </span>
                        </div>
                    </CCard>
               
                </div>
            </div>
            </div>
              
          </CCardBody>
        </CCard>
        <CCard color="primary">
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left  text-white">Direct Referal</h4> 
          </slot>
        </CCardHeader>
        <CCardBody>
            <CListGroup>
              <CListGroupItem v-for="rs in referals" :key="rs.id" class=" text-white">{{rs.name}}</CListGroupItem>
            </CListGroup>
        </CCardBody>
        </CCard>

      </CCol>
    </CRow>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from 'axios';
import $ from "jquery";
import AddGoogleMap from "./GMap";

let user = JSON.parse(localStorage.getItem('user'));
export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  components: {
    AddGoogleMap,
  },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    },
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2019 },
        (value, index) => new Date().getFullYear() - index
      );
    },
  },
  mounted() {
    this.loadData();
  },
  data: function() {
    return {
      referals: [],      
      dr: "0",
      dq: "0",
      tq: "0",
      at: "0",
      refer : "https://app.blockscan.cloud/#/register/ref="+user.referal,
      tahun: new Date().getFullYear(),
    };
  },
  methods: {
    loadData: function() {
      let id_member = user.id_member;
      // alert(id_member);
          let idm;
          if(id_member=="0") {
            idm = "";
          }
          else{
            idm = id_member;
          }
          // alert(process.env.VUE_APP_BASE_URL+"dashboardstat/"+idm);
          

          axios
            .get(
              process.env.VUE_APP_BASE_URL+"dashboardstat/"+idm,
              false
            )
            .then((response) => {
              // if (response.data == "sukses") {
                // alert(JSON.stringify(response.data));
                this.dr = parseFloat(response.data.dr)+" ";
                this.dq = parseFloat(response.data.dq)+" ";
                this.tq = parseFloat(response.data.tq)+" ";
                this.at = parseFloat(response.data.at)+" ";
                // alert(JSON.stringify(response.data));
                

                // this.myModal2 = true;
              // } else {
              //   // alert(JSON.stringify(user));
              //   this.myModal2 = true;
              // }
            });

            axios
            .get(
              process.env.VUE_APP_BASE_URL+"referal/"+user.referal,
              false
            )
            .then((response) => {
                this.referals = response.data;
            });
      
    },
  },
};
</script>
